@import 'colors';
@import 'typography';

.pricing-card {
    border-radius: 1.5rem;
    padding-top: 3rem;
    padding-bottom: 3rem;
    padding-left: 1rem;
    padding-right: 1rem;
    text-align: center;
    background-color: $white;
    height: 100%;
    position: relative;
}

.large {
    background-color: $doriean;
    border-radius: 8px;
    border-color: $apporange;
    border-style: solid;
    border-radius: 1.5rem;
}

.pricing-card ul {
    margin-top: 1.5rem;
    margin-bottom: 2.5rem;
}

.pricing-item {
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 600;
    color: $light-iron;
    margin-bottom: 12px;
    text-align: left;
    list-style: none;
}

.pricing-card .btn {
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
    background-color: $indigo;
    border-color: $indigo;
    max-width: 100%;
    width: 100%;
}

.pricing-card .hidden {
    display: none;
}

.subscription-card .btn {
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-right: 1rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
    background-color: $iron;
    border-color: $iron;
    max-width: 100%;
    width: 100%;
}