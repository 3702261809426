$apporange: #FF5144;
$greenie: #B1BD65;
$piechie: #FCBFBC;
$indigo: #181430;

$grenie: #b1bd65;
$bloodie: #BD0000;
$ielou: #EAD200;

$iron: #000000;
$light-iron: #8C8CA1;

$doriean: #ECF1F4;
$cloudie: #FAFCFE;

$white: #ffffff;

.bg-indigo {
    background-color: $indigo;
}

.bg-bloodie {
    background-color: $bloodie;
}

.bg-peachie {
    background-color: $piechie;
}

.bg-apporange {
    background-color: $apporange;
}

.bg-greenie {
    background-color: $greenie;
}

.bg-ielou {
    background-color: $ielou;
}

.bg-doriean {
    background-color: $doriean;
}

.bg-cloudie {
    background-color: $cloudie;
}

.bg-white {
    background-color: $white;
}

.bg-iron {
    background-color: $iron;
}