@import 'colors';
@import 'typography';

.navbar {
    padding: 1.5rem;
}

.navbar-nav li {
    padding-left: 0.5rem;
    font-family: $main;
    font-size: 1rem;
    font-weight: 700;
}

.navbar .nav-link {
    color: $iron;
}

.navbar .btn-cta-primary {
    background-color: $apporange;
    color: $white;
    border-radius: 0.75rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    margin-left: 0.5rem;
    font-family: $app;
    font-size: 1rem;
    font-weight: 700;
}

.navbar .btn-cta-primary-dark {
    background-color: #000;
    color: $white;
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 0.5rem;
    font-family: $app;
    font-size: 0.75rem;
    font-weight: 700;
}

.navbar .btn-cta-primary-p0 {
    background-color: white;
    color: $iron;
    border-radius: 0.75rem;
    margin-left: 0.5rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
}

.navbar .btn-cta-outline {
    border-color: $white;
    color: $white;
    border-radius: 0.75rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-left: 2rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
}

.navbar .profile {
    border-radius: 20px;
}

.apps .btn-cta-outline {
    border-color: $iron;
    color: $iron;
    border-radius: 0.75rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
    width: 100%;
}

.apps .btn-cta-primary {
    color: $white;
    background-color: $apporange;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    border-radius: 0.75rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
}

.apps .btn {
    font-size: 0.85rem;
    font-family: $app;
    font-weight: 700;
}

.new-stage .btn-outline {
    border-color: $white;
    color: $apporange;
    border-radius: 0.75rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
    width: 100%;
    margin-top: 2rem;
}

.new-stage .btn-primary {
    background-color: $greenie;
    color: $white;
    border-radius: 0.75rem;
    font-size: 0.85rem;
    font-weight: 700;
    width: 100%;
    border-style: none;
    margin-top: 1rem;
}

.user-container {
    border-radius: 0.75rem;
}