@import 'colors';
@import 'typography';

.alert {
    color: $white;
}

.alert-danger {
    background-color: $apporange;
}

.alert-success {
    background-color: $greenie;
}

.alert button {
    width: 10px;
    height: 10px;
}

.alert p {
    font-size: 0.85rem;
    margin-bottom: 0;
}

.alert .close {
    color: white !important;
}

.dashboard-notification {
    border-radius: 1rem;
}

.good {
    background-color: #00BDAA;
}

.attention {
    background-color: #EAD200;
}

.severe {
    background-color: #FF5F5F;
}

.dashboard-notification .btn {
    color: $white;
    border-width: 0px;
    text-decoration: underline;
}

.dashboard-notification .btn:hover,
.dashboard-notification .btn:focus,
.dashboard-notification .btn:active {
    color: $white;
}