@import 'colors';
@import 'typography';

.review-list-info {
  color: $light-iron;
  font-size: 0.75rem;
  font-weight: 700;
}

.review-list-date {
  color: $light-iron;
  font-size: 0.625rem;
  font-weight: 600;
}

.review-item {
  padding-bottom: 2rem;
  border-width: 1px;
  border-color: $iron;
}

.sentiment-box {
  width: 1.5rem;
  height: 50%;
  border-radius: 1rem;
}

.review-item .bad {
  background-color: $bloodie;
}

.review-item .good {
  background-color: $greenie;
}

.review-item .neutral {
  background-color: $ielou;
}

.review-item .content {
  color: $light-iron;
  font-size: 0.85rem;
  font-weight: 400;
}

.review-item .title {
  font-size: 1rem;
  font-weight: 700;
  color: $iron;
}

.review-item .btn-outline-dark {
  border-radius: 0.5rem;
  color: &purple;
  font-size: 0.625rem;
  font-weight: 700;
}

.modal .btn-primary {
  border-width: 0rem;
  background-color: $greenie;
  color: $white;
  border-radius: 0.75rem;
  font-family: $app;
  font-size: 0.85rem;
  font-weight: 700;
  width: 100%;
}

.review-list {
  height: 400px;
  overflow-y: scroll;
}

.filter-container {
  border-radius: 0.5rem;
}

.reviews-list .dropdown-menu {
  max-height: 200px;
  overflow: scroll;
}

.keywords .btn-cta-primary {
  color: $white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.75rem;
  font-family: $app;
  font-size: 0.85rem;
  font-weight: 700;
  width: 100%;
}

.feedback-manager .btn-cta-primary {
  color: $white;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 0.75rem;
  font-family: $app;
  font-size: 0.85rem;
  font-weight: 700;
  width: 100%;
}

.feedback-manager .btn-outline {
  color: $iron;
  font-weight: 600;
  text-decoration: underline;
}