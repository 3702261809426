@import 'colors';
@import 'typography';

.star {
    color: rgb(255, 187, 0);
    font-weight: 700;
    font-family: $app;
    font-size: 1.75rem;
  }
  
  .star-grayed {
    color: rgb(212, 212, 212);
    font-weight: 700;
    font-family: $app;
    font-size: 1.75rem;
  }
  
  .rating-average {
    font-size: 0.625rem;
  }

  .small {
    font-size: 0.75rem;
  }