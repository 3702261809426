@import 'colors';
@import 'typography';

.iphone-frame {
    width: 375px;
    height: 768px;
    border-radius: 40px;
    border: 10px solid #000;
    background: #f9f9f9;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    overflow: hidden;
    margin: 20px auto;
    position: relative;
}

.iphone-notch {
    width: 100px;
    height: 30px;
    background: #000;
    border-radius: 15px;
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
}

.screen {
    flex: 1;
    background: rgba(0, 0, 0, 0.8);
}

.preview {
    margin-top: 60px;
    border-radius: 24px 24px 0 0;
    padding: 16px;
    text-align: center;
    height: 100%;

}

.check {
    background-color: white;
    border-radius: 0.5rem;
    border: 1px solid;
    border-color: $iron;
    width: 24px;
    height: 24px;
}

.progress {
    border-radius: 0.25rem;
}

.preview .preview-btn {
    border-radius: 0.75rem;
    width: 100px;
}

.profile-settings {
    background-color: white;
    width: 48px;
    height: 48px;
    overflow: hidden;
}

.form-select-lg {
    font-size: 0.90rem;
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
}