@import 'colors';
@import 'typography';

.progress {
  border-radius: 0.5vh;
  height: 10px;
}

.rating-evolution {
    overflow : scroll;
    border-radius: 0.5rem;
}

.rating-version {
    height: 200px;
    position: relative;
    align-items: center;
    text-align: center;
}

.rating-bar {
    width: 0.5rem;
    border-radius: 4px;
    position: absolute;
    bottom: 0;
    left: 45%;
}

.sentiment-bar {
  border-radius: 4px;
  width: 10px;
  position: absolute;
  bottom: 0;
}

.sentiment-bar-container {
  padding-left: 0.5rem;
}

.sentiment-card {
  border-radius: 10px;
  width: 100px;
  padding-top: 1rem;
}

.graph-line {
    border-top: 1px dashed $light-iron;
  }
  
  .bottom {
    position: absolute;
    bottom: 55px;
    width: 95%;
  }
  
  .middle {
    position: absolute;
    bottom: 155px;
    width: 95%;
  }
  
  .top {
    position: absolute;
    width: 95%;
  }