@import 'colors';
@import 'typography';


.dropdown-toggle {
    font-size: 0.625rem;
    font-weight: 700;
}

.dropdown-item {
    font-size: 0.625rem;
    font-weight: 700;
}

.action .btn {
    font-size: 0.625rem;
    font-weight: 700;
}
