@import 'colors';
@import 'typography';

.full-height {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    height: 100%;
}

.side-menu {
    padding-left: 1rem;
}

.side-menu .nav-link {
    background-color: $iron;
    color: $white;
    border-radius: 1rem;
    padding-left: 1rem;
    font-family: $app;
    font-size: 0.85rem;
    font-weight: 700;
    width: 100%;
    text-align: left;
}

.side-menu .nav-link.active {
    background-color: $white;
    color: $iron;
}