@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@300;400;500;600;700&display=swap');

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif, 'Work Sans', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.full {
  min-height: 100vh;
}

.code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.noise {
  background: linear-gradient(to right, rgba(117, 189, 101, 1), rgba(252, 191, 188, 0)), url(https://grainy-gradients.vercel.app/noise.svg);
  /* background: 	radial-gradient(circle at 25% 25%, rgba(117,189,101,1), rgba(252,191,188,1)), url(https://grainy-gradients.vercel.app/noise.svg); */

}

.feedback {
  padding: 3rem;
  background-color: $light-gray;
}

.feedback-avatar {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  background-color: gainsboro;
}