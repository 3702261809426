@import 'colors';
@import 'typography';

.main-banner {
    padding: 0px;
}

.main-banner img {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    background-color: $apporange;
}

a img {
    background-color: $white !important;
}

.main-banner .btn {
    border-radius: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $app;
    font-size: 1rem;
    font-weight: 700;
    background-color: $apporange;
    color: $white;
}

.main-banner .btn-outline {
    color: white;
}

/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 1280px) {

    .main-banner img {
        display: none;
    }

    a img {
        display: block !important;
    }
}