@import 'colors';
@import 'typography';

.section {
    border-radius: 1.75rem;
    background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%);
}

.section .version {
    border-radius: 20px;
    opacity: 0.75;
    text-align: center;
    width: 40px;
    height: 40px;
    padding: 8px;
}

.section .btn {
    border-radius: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $app;
    font-size: 1rem;
    font-weight: 700;
    background-color: $white;
    color: $apporange;
}

.section-colored .btn {
    border-radius: 0.75rem;
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-family: $app;
    font-size: 1rem;
    font-weight: 700;
    background-color: $apporange;
    color: $white;
}

.main-banner .btn-outline {
    color: white;
}

.code-snippet {
    padding: 1.25rem;
    background-color: $white;
    border-radius: 1rem;
}