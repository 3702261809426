@import 'colors';
@import 'typography';

.full-page {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    height: 100%;
    position: absolute;
}

.form-control {
    font-family: $app;
    font-size: 1rem;
    color: $light-iron;
    padding: 1rem 1rem;
    background-color: $white!important;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    border-radius: 0.5rem;
    height: 54px;
}

.form-control:active,
.form-control:focus,
.form-control:focus:active {
  background-color:$white!important;
  border: 1px solid;
  border-color: $greenie;
  color: $iron!important;
  box-shadow: 0px 0px 0px 3px #b1bd6536; 
}

.login-btn {
    background-color: $apporange;
    width: 100%;
}

.cookies {
    text-align: left;
    color: white;
    background-color: $iron;
    position: fixed;
    bottom: 0;
    padding: 1rem;
}