@import 'colors';
@import 'typography';

.card {
    border-radius: 1rem;
    padding: 2rem;
}

.reviews-card {
    border-radius: 0.5rem;
    padding: 2rem 2rem 1rem 2rem;
}

.app-icon {
    border-radius: 1rem;
}