@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

@import 'colors';

$main: 'Inter', sans-serif;
$app: 'Inter', sans-serif;

h1 {
    font-family: $main;
    font-weight: 500;
    font-size: 2rem;
}

h2 {
    font-family: $main;
    font-weight: 400;
    font-size: 2rem;
}

h3 {
    font-family: $main;
    font-weight: 400;
    font-size: 1.5rem;
}

h4 {
    font-family: $main;
    font-weight: 600;
    font-size: 1.5rem;
}

h5 {
    font-family: $app;
    font-weight: 400;
    font-size: 1.25rem;
}

h6 {
    font-family: $app;
    font-weight: 600;
    font-size: 1.25rem;
}

p {
    font-family: $app;
    font-weight: 400;
    font-size: 1rem;
}

.indicator {
    font-family: $main;
    font-weight: 600;
    font-size: 3.5rem;
    line-height: 90%;
}

.large-heading {
    font-family: $main;
    font-weight: 700;
    font-size: 4rem;
    color: $iron;
}

.heading {
    font-family: $main;
    font-weight: 700;
    font-size: 3rem;
    color: $iron;
}

.tagline {
    font-family: $main;
    font-weight: 400;
    font-size: 1.5rem;
    color: rgba($color: $iron, $alpha: 0.6);
}

.feature-title {
    font-family: $app;
    font-weight: 600;
    font-size: 1.5rem;
}

.title-text {
    font-family: $app;
    font-weight: 400;
    font-size: 2rem;
}

.legend {
    font-size: 0.75rem;
}

.info {
    font-size: 0.875rem;
}

.hint {
    font-size: 0.625rem;
}

.balance {
    font-size: 4rem;
}

.tagline .white {
    color: rgba($color: $cloudie, $alpha: 1);
}

.code-snippet p {
    font-family: 'Courier New', Courier, monospace;
    font-weight: 600;
    font-size: 0.90rem;
}

.bold {
    font-weight: 700;
}

.black {
    font-weight: 900 !important;
}

.semi-bold {
    font-weight: 600;
}

.regular {
    font-weight: 400;
}

.light {
    font-weight: 300;
}

.underlined {
    text-decoration: underline;
}

.no-decoration {
    text-decoration: none;
}

.apporange {
    color: $apporange;
}

.iron {
    color: $iron;
}

.light-iron {
    color: $light-iron;
}

.greenie {
    color: $greenie;
}

.bloodie {
    color: $bloodie;
}

.white {
    color: $white;
}

.id-sample {
    border-radius: 0.75rem;
    border-width: 1px;
    border-color: $white;
    border-style: solid;
}

.quote-indicator {
    font-family: $main;
    font-weight: 800;
    font-size: 2rem;
    color: $apporange;
}

/* Smartphones (portrait and landscape) ----------- */
@media (max-width: 800px) {

    .large-heading {
        font-size: 3rem;
        color: $iron;
    }
}