@import 'colors';
@import 'typography';

.pill p {
    color: white;
    font-size: 0.625rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 1vh;
}

.small-pill {
    color: white;
    width: 80px;
    font-size: 0.625rem;
    font-weight: 600;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 1vh;
}

.light p {
    background-color: $light-iron;
}