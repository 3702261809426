@import 'colors';
@import 'typography';

.predictor-title p {
    font-size: 1.25rem;
}

#reportAccordion .btn {
    width: 100%;
}

.wi-container {
    background-color: white;
    border-radius: 1rem;
    border-width: 1px;
    border-color: $iron;
    border-style: solid;
    color: $iron;
}

.pill {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    border-radius: 1vh;
}

.category {
    padding: 0.35rem;
    border-radius: 0.5vh;
    background-color: $cloudie;
    color: $iron;
    border-width: 1px;
    border-color: $doriean;
    border-style: solid;
    font-size: 0.65rem;
}

.pill p {
    color: white;
    font-size: 0.85rem;
    font-weight: 600;
}