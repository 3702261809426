@import 'colors';
@import 'typography';

.gauge {
  position: relative;
  border-radius: 50%/100% 100% 0 0;
  background-color: var(--color, #a22);
  overflow: hidden;
}

.gauge:before {
  content: "";
  display: block;
  padding-top: 50%;
  /* ratio of 2:1*/
}

.gauge .chart {
  overflow: hidden;
}

.gauge .mask {
  position: absolute;
  left: 5%;
  right: 5%;
  bottom: 0;
  top: 10%;
  background-color: #fff;
  border-radius: 50%/100% 100% 0 0;
}

.gauge .percentage {
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: 0;
  right: -1px;
  background-color: var(--background, #f3f3f3);
  transform: rotate(var(--rotation));
  transform-origin: bottom center;
  transition-duration: 600;
}

.gauge:hover {
  --rotation: 100deg;
}

.gauge .min {
  position: absolute;
  bottom: 0;
  left: 5%;
}

.gauge .max {
  position: absolute;
  bottom: 0;
  right: 5%;
}