@import 'colors';
@import 'typography';

.pagination {
    color: $iron;
    border-radius: 1rem;
}

.pagination-sm {
    color: $iron;
}

.page-item {
    border-radius: 1rem;
}

.page-item {
    background-color: $piechie;
}

.page-link {
    color: $iron;
    font-weight: 700;
    font-size: 0.625rem;
}

.active>.page-link, .page-link.active {
    z-index: 3;
    border-color: lightgray;
    color: $iron;
    background-color: $piechie;
}